import getters from '../product_list_v2/getters'
import { mapStickyServiceBeltLabelInfo } from './utils'
import { TOP_HEADER_HEIGHT, TAB_CONTAINER_TAB_HEIGHT, SERVICE_LABEL_BELT_HEIGHT } from 'public/src/pages/store_pages/js/constants'

export default {
  ...getters,
  // 店铺信息--店招
  storeData: (state) => {
    return state.Results.storeData || {}
  },
  storeOperation: (state) => {
    return state.Results.storeOperation || {}
  },
  // 店铺 tab
  storeTabConfig: (state) => {
    return state.Results.storeTabConfig || {}
  },
  // 是否展示 toptab
  showTopTab: (state) => {
    return !!state.Results?.storeTabConfig?.showTopTab  
  },
  storeActiveTab: (state)=>{
    return state.Results?.storeTabConfig.topTabActive  
  },
  // 店铺促销
  storePromotionData: (state) => {
    return state.Results.storePromotionData || {}
  },
  // 促销激活 tab
  promotionInfoActiveIndex: (state) =>{
    return state.Results.promotionInfoActiveIndex || 0
  },
  // 是否有促销promo tab
  hasPromotionTab: (state) => {
    return !!state.Results?.storePromotionData?.hasPromotionTab
  },
  // 是否展示吸底条
  isShowPromotionBottomStrip: (state, getters)=>{
    const { isCouponInfo, hasPromotionTab } = state.Results?.storePromotionData || {}
    return hasPromotionTab || (isCouponInfo && getters.storecouponaddAbt !== 'A') 
  },
  // 闪购数据
  storePromoFlashData: (state)=>{
    return state.Results?.storePromotionData?.storePromoFlashData || {}
  },

  // 是否是品牌店铺
  isBrandStore: (_, getters) => {
    return !!getters.storeData?.isBrandStore
  },
  storeAbtResult: (state) => {
    const { storeAbtResult } = state.Results || {}
    return storeAbtResult || {}
  },
  storeDeliverInfo: (state) => {
    return state.Results.storeDeliverInfo || {}
  },
  // 店铺选品Id
  storeSelectId: (state) => {
    return state.Results?.cat_info?.select_id || ''
  },
  FlashSaleCountDown: (state)=>{
    const { storeAbtResult = {} } = state.Results
    return  storeAbtResult?.FlashSaleCountDown?.p?.FlashSaleCountDown || 'Show' // 默认是Show
  },
  // 是否店招可见
  isShopBannerVisible: (state) => {
    return !!state.Results.isShopBannerVisible
  },
  tabFixedServiceLabels: (_, getters) => {
    const { storeData, language } = getters
    return storeData.isBff ? 
      storeData.serviceLabels || [] :
      mapStickyServiceBeltLabelInfo(storeData?.storeServicePolicyLabel || [], language)
  },
  showShortShopBannerServiceBelt: (_, getters) => {
    return getters.tabFixedServiceLabels?.length > 0
  },
  // 店铺 tab 上面的内容高度
  tabTopFixedContentHeightRem: (_, getters) => {
    if (getters.showShortShopBannerServiceBelt) return SERVICE_LABEL_BELT_HEIGHT
    return 0
  },
  // 店铺 顶部tab 内 吸顶 Offset
  storeTabInnerStickyOffset: (_, getters)=>{
    return TOP_HEADER_HEIGHT + TAB_CONTAINER_TAB_HEIGHT + getters.tabTopFixedContentHeightRem
  },
  filterPanelShow: (state) => {
    return state.Results.filterPanelShow || false
  },
  suggestedSalePriceType: (state)=>{
    return state.Results.suggestedSalePriceType
  },
  flowStoreFollowing: (state) => {
    return state.ComponentState?.ProductList?.flowDataV2?.flowStoreFollowing
  },
  isFollowed: (state) => {
    return state.Results.isFollowed
  },

  pageDataResolveTime(state){ // 客户端数据 页面数据 resolve 时间
    return state.Results.pageDataResolveTime
  },
  cateAdp: (state) => {
    return state.Results.cateAdp || ''
  },
  // 新悬浮购物车 abt
  storeCartPopoverAbtValue: (state, getters) => {
    return getters.storeAbtResult.StoreCartPopover?.p?.StoreCartPopover || ''
  },
  // 新悬浮购物车需曝光数量 0表示没有命中expose abt
  storeCartPopoverRequiredExposeNum(state, getters){
    const matchExposeValue = getters.storeCartPopoverAbtValue.match(/expose=(\d+)/)
    if(!matchExposeValue || !matchExposeValue[1]) return 0
    return Math.max(10, matchExposeValue[1])
  },
  storecouponaddAbt(_, getters){ // 凑单pageId=1442858939
    return getters.storeAbtResult.storecouponadd?.p?.storecouponadd || 'A'
  },
  storeEstimatedPriceAbt: (_, getters)=>{
    return getters.storeAbtResult.StoreEstimatedPrice?.p?.StoreEstimatedPrice || ''
  },
  storeboardfoldAbt: (_, getters)=>{ // pageId=1493704368 tab吸顶实验
    return getters.storeAbtResult.storeboardfold?.p?.storeboardfold || 'A'
  },
  storerecempty: (_, getters)=>{ // 店铺兜底推荐
    return getters.storeAbtResult.storerecempty?.p?.storerecempty || 'A'
  },
  isHitDefaultChild: (state)=>{ // 用于列表是否选中童装判断
    return state.Results.isHitDefaultChild || false
  }
}
