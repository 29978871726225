import actions from '../product_list_v2/actions'
import schttp from 'public/src/services/schttp'
import { runDelay } from 'public/src/js/utils/index.js'

export default {
  ...actions,
  dealingNewData({ commit, getters }, payload) {
    commit('dealingPageInfos', payload)
    commit('initProductList', getters)
    commit('dealingGoods', payload)
    commit('dealingTrackings', payload)
    if (payload.cat_info?.requestType === 'firstload') {
      commit('dealingFlowDataV2', payload)
    }
    commit('updateFlowRankVisible', payload)
    if (payload.cat_info.requestType !== 'nextpage') {
      commit('dealingKidsTag', payload)
      commit('dealingOriginFilters', payload)
      commit('dealingAttributes', payload)
      commit('dealingCloudTags', payload)
      commit('dealingFilterTsps', payload)
    }
  },
  initState({ getters, commit }) {
    commit('initPage', getters)
    // commit('initStoreBanner', getters)
    commit('initQueryParams', getters)
    commit('initFilterBar', getters)
    commit('initProductList', getters)
    commit('initNoResult', getters)
    commit('initListBottomInfo', getters)
  },
  async fetchComponentAsyncData({ dispatch }) {
    await Promise.all([
      // dispatch('fetchAtomicInfo'),
      dispatch('fetchFlowData')
    ])
  },
  // 流式数据 (归并渲染)
  async fetchFlowData({ state: { ComponentState }, dispatch }) {
    const FlowData = await Promise.all([
      // 广告流
      dispatch('_getFlowAdData'),
      dispatch('_getFlowRealCate')
    ])

    ComponentState.ProductList.FlowData = {
      ...ComponentState.ProductList.FlowData,
      ...FlowData.reduce((curr, next) => ({ ...curr, ...next }), {})
    }

    dispatch('getFlowMap')

    // 店铺隐藏列表组件的悬浮购物车
    ComponentState.ProductList.config = {
      ...ComponentState.ProductList.config,
      showCartBag: false,
      // 是否不展示时尚店铺标签
      hideModStoreTag: true,
    }
  },
  // 客户端获取人气氛围数据
  async getAtmosphere ({ commit, getters }) {
    const { storeDeliverInfo, storeData, goods  } = getters
    const data = {
      showType: storeData.storeServiceNiceDataType,
      storeCode: storeData.storeCode
    }
    if (storeDeliverInfo) {
      data.machineLabels = storeDeliverInfo.machineLabels
      if (storeDeliverInfo.ccc?.recentBuy) {
        data.goodsSns = (goods || []).map(i => i.goods_sn)
      }
    }
    const resData = await schttp({
      url: '/api/store/atmosphere/get',
      data,
      method: 'POST'
    })
    runDelay(() => {
      commit('updateStoreDeliverInfo', resData)
    }, 1000)

  },
  // 客户端获取店铺券数据
  async fetchStorePromotionCouponInfo({ commit }, payload) {
    const { store_code  } = payload
    const resData = await schttp({
      method: 'GET',
      url: '/api/store/storePromotionCouponInfo/query',
      params: {
        store_code,
      }
    })
    const { code, data = {} } = resData || {}
    if(code !== 0) return
    commit('updateStorePromotionCouponInfoData', data || {})
    return resData
  },
  resetFollowFlowMap({ state }) {
    // 过滤掉关注数据
    state.ComponentState.ProductList.flowDataV2.flowStoreFollowing = {}
    const flowMap = { ...state.ComponentState.ProductList.flowMap }
    Object.keys(flowMap).forEach(key => {
      const data = flowMap[key].results[0]
      if (data?.compTag === 'FlowStoreFollowing') {
        delete flowMap[key]
      }
    })
    state.ComponentState.ProductList.flowMap = flowMap
  }
}
